export const actionTypes = {
  GET_SETTING_CREDENTIALS: 'GET_SETTING_CREDENTIALS',
  GET_SETTING_CREDENTIALS_SUCCESS: 'GET_SETTING_CREDENTIALS_SUCCESS',
  GET_SETTING_CREDENTIALS_ERROR: 'GET_SETTING_CREDENTIALS_ERROR',
  CLEAN_SETTING_CREDENTIALS: 'CLEAN_SETTING_CREDENTIALS'
};

export const getSettingCredentials = ({ variables }) => ({
  type: actionTypes.GET_SETTING_CREDENTIALS,
  variables
});

export const getSettingCredentialsSuccess = (payload) => ({
  type: actionTypes.GET_SETTING_CREDENTIALS_SUCCESS,
  payload
});

export const getSettingCredentialsError = (error) => ({
  type: actionTypes.GET_SETTING_CREDENTIALS_ERROR,
  payload: error
});

export const clearSearch = () => ({ type: actionTypes.CLEAN_SETTING_CREDENTIALS });
