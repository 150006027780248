import { combineReducers } from 'redux';

import analyticReducer from './analytic';
import benefitsReducer from './benefits';
import commentsReducer from './comments';
import commonsReducer from './commons';
import companyChartReducer from './companyChart';
import userReducer from './user';
import agreementsReducer from './agreements';
import liveHealthyReducer from './LiveHealthy';
import faqReducer from './faq';
import miBonoReducer from './mibono';
import contactReducer from './contact';
import reportReducer from './report';
import visibleReducer from './visible';
import socialAssistant from './socialAssistant';
import payingReducer from './paying';
import adminReducer from './admin';
import compensationsReducer from './compensations';
import milugarReducer from './milugar';
import profileReducer from './profile';
import miCredencialReducer from './micredencial';
import returningPlanReducer from './returningPlan';
import waliReducer from './wali';
import muralReducer from './mural';
import searchReducer from './search';
import podiumReducer from './podium';
import fourChartReducer from './fourChart';
import sectionReducer from './section';
import operationsAcademyReducer from './operationsAcademy';
import digitalFolderReducer from './digitalFolder';
import myTimeReducer from './myTime';
import evaluationsReportReducer from './evaluationsReport';
import calibrationsReportReducer from './calibrationsReport';
import uniformsReducer from './uniforms';
import helpReducer from './help';
import anticipoSueldoReducer from './anticipoSueldo';
import rankingRecoReducer from './rankingReco';
import searchUserReducer from './searchUser';
import misReconocimientosReducer from './misReconocimientos';
import settingsReducer from './settings';
import settingCredentialsReducer from './settingCredentials';
import leadersPeopleStoresReducer from './leadersPeopleStores';
import reactionsMuralReducer from './reactionsMural';
import banksReducer from './banks';
import nuestraCompaniaReducer from './nuestraCompania';

import reportsReducer from './reports';

export default combineReducers({
  payingReducer,
  analyticReducer,
  benefitsReducer,
  commentsReducer,
  commonsReducer,
  companyChartReducer,
  userReducer,
  agreementsReducer,
  liveHealthyReducer,
  faqReducer,
  miBonoReducer,
  contactReducer,
  reportReducer,
  visibleReducer,
  socialAssistant,
  adminReducer,
  compensationsReducer,
  milugarReducer,
  helpReducer,
  profileReducer,
  miCredencialReducer,
  returningPlanReducer,
  waliReducer,
  muralReducer,
  searchReducer,
  podiumReducer,
  fourChartReducer,
  sectionReducer,
  operationsAcademyReducer,
  digitalFolderReducer,
  myTimeReducer,
  evaluationsReportReducer,
  calibrationsReportReducer,
  uniformsReducer,
  anticipoSueldoReducer,
  rankingRecoReducer,
  misReconocimientosReducer,
  settingsReducer,
  settingCredentialsReducer,
  searchUserReducer,
  leadersPeopleStoresReducer,
  reactionsMuralReducer,
  banksReducer,
  nuestraCompaniaReducer,
  reportsReducer
});
