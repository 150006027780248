import { useState } from 'react';
import { message } from 'antd';
import usePerformance from './usePerformance';

const useSuccessMeasure = ({ defaultData, getFieldsValue, setFieldsValue }) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent: currentQuarter } = performance;

  const [defaultDeleted, deleteDefault] = useState(false);
  const fields = getFieldsValue();
  const defaultsuccessMeasures = (
    (!defaultDeleted && defaultData.length) ? defaultData : [{ description: '' }]);
  const successMeasures = fields.successMeasures || defaultsuccessMeasures;

  const filterSuccessMeasuresLength = () => {
    const res = successMeasures.filter(({ updates = {} }) => {
      const status = updates[currentQuarter]?.status;

      if ([
        undefined,
        'NOT_INIT',
        'IN_PROGRESS',
        'WITHOUTINFO'].includes(status)) {
        return true;
      }
      return false;
    }).length;

    return res;
  };

  const [successMeasuresLength, increaseSuccessMeasuresLength] = useState(successMeasures.length);

  const addSucessMeasure = async () => increaseSuccessMeasuresLength(successMeasuresLength + 1);

  const copySucessMeasure = async (description) => {
    navigator.clipboard.writeText(description);
    message.success('Texto copiado');
  };

  const removeSucessMeasure = async (index) => {
    const newSucessMeasure = {};

    for (let i = 0, j = 0; i < successMeasuresLength; i += 1) {
      if (i !== index) {
        const measure = {};

        if (successMeasures[i]) {
          const successMeasuresKeys = Object.keys(successMeasures[i]);
          successMeasuresKeys.forEach((measureKey) => {
            const newMeasure = {
              [`successMeasures[${j}][${measureKey}]`]: successMeasures[i][measureKey]
            };
            return Object.assign(measure, newMeasure);
          });

          Object.assign(newSucessMeasure, measure);
          j += 1;
        }
      }
    }

    await increaseSuccessMeasuresLength(successMeasuresLength - 1);
    if (!defaultDeleted) deleteDefault(true);

    setFieldsValue(newSucessMeasure);
  };

  return {
    addSucessMeasure,
    copySucessMeasure,
    removeSucessMeasure,
    filterSuccessMeasuresLength,
    successMeasuresLength
  };
};

export default useSuccessMeasure;
