import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { get } from 'lodash';
import { getSettingCredentialsAPI } from '../api/settingCredentials';
import { actionTypes, getSettingCredentialsSuccess, getSettingCredentialsError } from '../actions/settingCredentials';

es6promise.polyfill();

function* getSettingCredentials({ variables }) {
  try {
    const response = yield call(getSettingCredentialsAPI, variables);
    yield put(getSettingCredentialsSuccess(response));
  } catch (err) {
    const error = get(err, 'response.data');
    const statusCode = get(err, 'response.status');
    yield put(getSettingCredentialsError({ error, statusCode }));
  }
}

function* searchSagas() {
  yield all([takeLatest(actionTypes.GET_SETTING_CREDENTIALS, getSettingCredentials)]);
}
export default searchSagas;
