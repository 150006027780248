import React, { useState } from 'react';
import { Form, Row } from 'antd';
import { useMutation } from '@apollo/client';
import { find, isEmpty } from 'lodash';
import ObjectiveWeight from './shared/ObjectiveWeight';
import ObjectivePillar from './shared/ObjectivePillar';
import Objective from './shared/Objective';
import HideBossObjectives from './shared/HideBossObjectives';
import SuccessMeasures from './shared/SuccessMeasures';
import '../../../../../../static/styles/mievolucion/myPerformance/objective-form.less';
import RenderField from './shared/RenderField';
import { CREATE_OBJECTIVE_WALMART } from '../../../../../../graphql/mievolucion/myPerformance/mutations';
import ModalMessage from '../../../../../shared/ModalMessage';
import getInputForCreate from '../../../../../../helpers/myEvolution/myPerformance/getInputForCreate';
import setObjectiveFormDefaults from '../../../../../../helpers/myEvolution/myPerformance/setObjectiveFormDefaults';
import {
  ERROR_MESSAGE_MAX_SUCCESS_MEASURES,
  TITLE_ERROR_MAX_SUCCESS_MEASURES,
  getNumberOfActivesSuccessMeasures
} from '../../../../../../helpers/myEvolution/myPerformance';
import getObjectivesValidateValues, { getObjetivesValuesToDisplay } from '../../../../../../helpers/myEvolution/myPerformance/getObjectivesValidateValues';

const FormItem = Form.Item;

const initStateFormItems = {
  pillarSelected: {},
  objective: null,
  objectiveDescription: null,
  otherObjective: ''
};
const AddObjective = ({
  form, formRef, onSubmit, srcData, currentQuarter
}) => {
  const [modalMessage, setModalMessage] = useState({ visible: false });
  const [formItems, setFormItems] = useState(initStateFormItems);

  const {
    otherObjective,
    pillarSelected,
    objective,
    objectiveDescription
  } = formItems;

  const { maxSuccessMeasures } = getObjetivesValuesToDisplay();

  const { validateFieldsAndScroll } = form;

  const {
    dataPillarsWalmart: { getPillarWalmart: pillars },
    dataPerformanceWalmart
  } = srcData;

  const [createObjective] = useMutation(
    CREATE_OBJECTIVE_WALMART,
    {
      context: { clientName: 'myEvolution' }
    }
  );

  const handleSubmit = (events) => {
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const { successMeasures } = values;
          const activesQty = getNumberOfActivesSuccessMeasures(currentQuarter, successMeasures);

          const {
            maxSuccessMeasures:
            maxSuccessMeasuresToValidate
          } = getObjectivesValidateValues();

          if (activesQty > maxSuccessMeasuresToValidate) {
            setModalMessage({
              visible: true,
              title: TITLE_ERROR_MAX_SUCCESS_MEASURES,
              message: ERROR_MESSAGE_MAX_SUCCESS_MEASURES,
              type: 'error'
            });
          } else {
            const input = getInputForCreate({
              dataPerformanceWalmart,
              formItems,
              values
            });
            events.startLoading(values);
            await createObjective({ variables: { input } });
            onSubmit({ values, events });
          }
        } catch (error) {
          events.endLoading();
          setModalMessage({
            visible: true,
            title: 'Ha ocurrido un error repentino',
            message: error?.message || 'Por favor, vuelva a intentar mas tarde.',
            type: 'error'
          });
        }
      }
    });
  };

  const handleSelectObjectivePillar = ({ props }) => {
    const infoPillarSelected = find(pillars, ({ _id }) => _id === props.value);

    setFormItems({
      ...initStateFormItems,
      pillarSelected: {
        _id: props.value,
        name: props.value,
        objectives: infoPillarSelected?.data || []
      },
      objective: null,
      objectiveDescription: null
    });
  };
  const handleSelectObjective = ({ props }) => {
    const objectiveSelected = find(pillarSelected.objectives,
      ({ _id = [] }) => _id === props?.value);
    setFormItems({
      pillarSelected,
      objective: {
        value: props.value
      },
      objectiveDescription: objectiveSelected.description
    });
  };
  const onChangeOtherText = (value) => {
    setFormItems({
      pillarSelected,
      objective: null,
      objectiveDescription: null,
      otherObjective: value
    });
  };
  return (
    <Form
      ref={formRef}
      className='ml-performance-objective-form'
      onSubmit={handleSubmit}
    >
      <HideBossObjectives {...{ form, FormItem }}/>

      <ObjectivePillar {...{
        form,
        FormItem,
        pillars,
        isSelected: (value) => handleSelectObjectivePillar(value),
        objectiveDescription
      }} />

      <Objective {...{
        form,
        FormItem,
        pillarSelected,
        formItems,
        isSelected: (value) => handleSelectObjective(value),
        onChangeOtherText
      }} />

      {
        !isEmpty(objectiveDescription)
          && <RenderField {...{ label: 'Descripción', value: objectiveDescription }}/>

      }
      { objective || otherObjective?.trim().length > 0
        ? <>
          <Row className='mb-5'>
            <ObjectiveWeight {...{ form, FormItem }}/>
          </Row>
          <Row type='flex'>
            <i className='icon-ml-indicador color-indicator'/>
            <label>Medidas de éxito (máximo {maxSuccessMeasures}):</label>
          </Row>
          <SuccessMeasures {...{
            form, FormItem
          }}/>
        </>
        : <></>}

      <ModalMessage {...{
        ...modalMessage,
        closeModal: () => setModalMessage({ ...modalMessage, visible: false })
      }}/>
    </Form>
  );
};

export default Form.create({
  mapPropsToFields({ srcData, values }) {
    const { currentQuarter } = srcData;
    return setObjectiveFormDefaults({ srcData: { ...values, currentQuarter }, Form });
  }
})(AddObjective);
