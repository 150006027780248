import { lazy } from 'react';

const AnticipoSueldo = lazy(() => import('../../../../containers/solicitudes/anticipo-sueldo'));
const AnticipoRequest = lazy(() => import('../../../../containers/solicitudes/anticipo-sueldo/request'));

export default [
  {
    path: '/solicitudes/anticipo',
    section: 'Solicitudes -> Anticipo de sueldo',
    component: AnticipoSueldo,
    visible: true,
    exact: true
  },
  {
    path: '/solicitudes/anticipo/proceso-de-solicitud',
    section: 'Solicitudes -> Anticipo de sueldo -> Proceso de Solicitud',
    component: AnticipoRequest,
    visible: true,
    exact: true
  }
];
