/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';

import analyticSagas from './analytic';
import userSagas from './user';
import benefitsSagas from './benefits';
import commentsSagas from './comments';
import companyChartSagas from './companyChart';
import agreementsSagas from './agreements';
import liveHealthySagas from './liveHealthy';
import faqSagas from './faq';
import contactSagas from './contact';
import miBonoSagas from './mibono';
import reportSagas from './report';
import visibleSagas from './visible';
import socialAssistant from './socialAssistant';
import payingSagas from './paying';
import adminSagas from './admin';
import milugarSagas from './milugar';
import compensationSagas from './compensations';
import commonsSagas from './commons';
import profileSagas from './profile';
import miCredencialSagas from './micredencial';
import returningPlanSagas from './returningPlan';
import waliSagas from './wali';
import muralSagas from './mural';
import searchSagas from './search';
import podiumSagas from './podium';
import fourChartSagas from './fourChart';
import sectionSagas from './section';
import operationsAcademySagas from './operationsAcademy';
import digitalFolderSagas from './digitalFolder';
import evaluationsReportSagas from './evaluationsReport';
import calibrationsReportSagas from './calibrationsReport';
import uniformsSagas from './uniforms';
import helpSagas from './help';
import anticipoSueldoSagas from './anticipoSueldo';
import rankingRecoSagas from './rankingReco';
import searchUserSagas from './searchUser';
import misReconocimientosSagas from './misReconocimientos';
import settingsSagas from './settings';
import settingCredentialsSagas from './settingCredentials';
import leadersPeopleStoresSagas from './leadersPeopleStores';
import reactionsMuralSagas from './reactionsMural';
import banksSagas from './banks';
import nuestraCompaniaSagas from './nuestraCompania';
import reportsSagas from './reports';

export default function* rootSaga() {
  yield all([
    faqSagas(),
    userSagas(),
    commentsSagas(),
    companyChartSagas(),
    payingSagas(),
    miBonoSagas(),
    reportSagas(),
    contactSagas(),
    visibleSagas(),
    analyticSagas(),
    benefitsSagas(),
    socialAssistant(),
    agreementsSagas(),
    liveHealthySagas(),
    adminSagas(),
    milugarSagas(),
    compensationSagas(),
    commonsSagas(),
    profileSagas(),
    miCredencialSagas(),
    returningPlanSagas(),
    waliSagas(),
    muralSagas(),
    sectionSagas(),
    searchSagas(),
    podiumSagas(),
    fourChartSagas(),
    operationsAcademySagas(),
    digitalFolderSagas(),
    evaluationsReportSagas(),
    calibrationsReportSagas(),
    uniformsSagas(),
    helpSagas(),
    anticipoSueldoSagas(),
    rankingRecoSagas(),
    misReconocimientosSagas(),
    settingsSagas(),
    settingCredentialsSagas(),
    searchUserSagas(),
    leadersPeopleStoresSagas(),
    reactionsMuralSagas(),
    banksSagas(),
    nuestraCompaniaSagas(),
    reportsSagas()
  ]);
}
