const getObjectivesValidateValues = () => ({
  minWeightInput: 5,
  maxWeightInput: 50,
  expectedWeight: 100,
  minObjectives: 3,
  maxObjectives: 12,
  minSuccessMeasures: 1,
  maxSuccessMeasures: 5
});

export const getObjetivesValuesToDisplay = () => ({
  minWeightInput: 10,
  maxWeightInput: 40,
  expectedWeight: 100,
  minObjectives: 3,
  maxObjectives: 8,
  minSuccessMeasures: 1,
  maxSuccessMeasures: 4
});

export default getObjectivesValidateValues;
